import i18next from 'i18next';
import { accessRoute } from '../../routes/Dashboard';
import { accessRoute as customerRoute } from '../../routes/Customers';
import {
  dateFormatter,
  phoneNumberFormate,
  showDateInBrowser,
} from '../../utils';
import {
  ButtonFormatter,
  checkValidData,
  linkFormatter,
  serialNumberFormatter,
} from '../../components/UIElements/Formatter';
import { getFullName } from '../../utils/text.util';
import {
  getColumnSelectSearchProps,
  getInputSearchFilter,
  getMultiSelectSearchFilter,
} from '../../components';
import { getFromToDateFilter } from '../../components/Filter/Common/date.filter';
import { Localization } from '../Localization';
import {
  getStatusData,
  listAdminNCACompanyService,
  memberData,
} from '../index.service';
import { accessRoute as ManualBookingRoute } from '../../routes/ManualBooking';
import { accessRoute as serviceManagement } from '../../routes/ServiceManagement';
import { DATE_TH_MONTH_YEAR } from '../../config';
import { CustomerDetails } from '../../views/Customers';
import MemberShipPlan from '../../views/Customers/MemberShipPlan';

export const customersTableColumn = ({
  Action,
  Toggle,
  onSort,
  page,
  onFilterChange,
  onHandleShow,
  filter,
  sizePerPage,
  userRole,
}) => {
  let newCustomerColumn = [
    {
      dataIndex: 'id',
      title: i18next.t('text.common.serialNo'),
      headerClasses: 'w_70',
      render: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
      exportFormatter: (cell, row, index) =>
        serialNumberFormatter({ page, sizePerPage, index }),
    },
    {
      dataIndex: 'firstName',
      title: i18next.t('text.common.customerName'),
      // className: 'text-capitalize',
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('firstName', order),
      exportFormatter: (cell, row) =>
        getFullName(row?.firstName, row?.middleName, row?.lastName),
      render: (cell, row) => {
        return linkFormatter(
          onHandleShow,
          // getFullName(row?.firstName, row?.middleName, row?.lastName),
          `${row?.firstName || ''}${' '}${row?.middleName || ''}${' '}${
            row?.lastName || ''
          }`,
          row?.id,
          row?.Customer?.CompanyNca,
          userRole
        );
      },
      ...getInputSearchFilter({
        dataIndex: 'firstName',
        placeholder: 'customer name',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'email',
      title: i18next.t('text.common.email'),
      headerClasses: 'sorting',
      sorter: true,
      onSort: (field, order) => onSort('email', order),
      render: (cell, row) => checkValidData(cell),
      ...getInputSearchFilter({
        dataIndex: 'email',
        placeholder: 'email',
        onFilterChange,
        filter,
      }),
    },

    {
      dataIndex: 'createdAt',
      filterDataIndex: ['fromDate', 'toDate'],
      title: i18next.t('text.customers.signUpDate'),
      sorter: true,
      exportFormatter: (cell, row) =>
        showDateInBrowser(cell, DATE_TH_MONTH_YEAR),
      render: (cell, row) => showDateInBrowser(cell, DATE_TH_MONTH_YEAR),
      ...getFromToDateFilter({
        dataStartIndex: 'fromDate',
        dataEndIndex: 'toDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'phoneNumber',
      title: i18next.t('text.common.mobile'),
      sorter: true,
      exportFormatter: (cell, row) => {
        const formatNumber = phoneNumberFormate(cell, row?.countryPhoneCode);
        return formatNumber ?? '-';
      },
      render: (cell, row) => {
        const formatNumber = phoneNumberFormate(cell, row?.countryPhoneCode);
        return formatNumber ?? '-';
      },
      ...getInputSearchFilter({
        dataIndex: 'phoneNumber',
        placeholder: 'mobile',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'location',
      exportName: 'Country Name',
      title: i18next.t('text.common.location'),
      export: true,
      headerClasses: 'sorting',
      sorter: true,
      sortType: 'location',
      exportFormatter: (cell, row) =>
        checkValidData(row?.Customer?.Country?.name),
      render: (cell, row) => checkValidData(row?.Customer?.Country?.name),
      ...getMultiSelectSearchFilter({
        dataIndex: 'location',
        onFilterChange,
        name: 'location',
        list: [],
        filter,
        mode: false,
        scope: 'activeCountry',
        listService: Localization.CountryListService,
      }),
    },
    {
      dataIndex: 'viewBooking',
      title: i18next.t('text.customers.ViewBookingDetail'),
      headerClasses: 'sorting',
      onSort: (field, order) => onSort('viewBooking', order),
      render: (cell, row) =>
        ButtonFormatter(
          `${serviceManagement.LIST_SERVICE.path}?customerId=${row?.id}&customerName=${row?.firstName}`,
          'View',
          '_blank'
        ),
    },
    {
      dataIndex: 'userAddress',
      title: i18next.t('text.customers.viewUserAddress'),
      sort: false,
      export: false,
      onSort: (field, order) => onSort('userAddress', order),
      render: (cell, row) =>
        ButtonFormatter(
          `${customerRoute.CUSTOMERS_VIEW.path}/${row?.id}`,
          'View',
          '_blank'
        ),
    },
    {
      dataIndex: 'bookNow',
      title: i18next.t('text.customers.bookNow'),
      render: (_, row) =>
        !row?.Customer?.CompanyNca
          ? ButtonFormatter(
              `${ManualBookingRoute.ADD_BOOKING.path}`,
              'Book Now',
              '_blank',
              { key: 'bookingDetails', value: JSON.stringify(row) }
            )
          : userRole === 'nca'
          ? ButtonFormatter(
              `${ManualBookingRoute.ADD_BOOKING.path}`,
              'Book Now',
              '_blank',
              { key: 'bookingDetails', value: JSON.stringify(row) }
            )
          : '-',
    },
    {
      dataIndex: 'memberPlan',
      title: 'Membership Plan',
      headerClasses: 'sorting',
      sorter: false,
      exportName: 'Membership Plan',
      exportKey: 'CustomerMembership',
      export: true,
      exportFormatter: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      render: (cell, row) =>
        checkValidData(row?.CustomerMembership?.MembershipPlan?.name),
      ...getInputSearchFilter({
        dataIndex: 'memberPlan',
        placeholder: 'membership plan',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'membershipStatus',
      title: i18next.t('text.quote.memberShipStatus'),
      headerClasses: 'sorting',
      sorter: false,
      render: (cell, row) => (!cell ? 'Non-Member' : checkValidData(cell)),
      ...getColumnSelectSearchProps({
        dataIndex: 'membershipStatus',
        name: 'membership status',
        onFilterChange,
        filter,
        mode: false,
        list: memberData(),
      }),
    },
    {
      dataIndex: 'memberRenewalDate',
      filterDataIndex: ['membershipFromDate', 'membershipToDate'],
      title: i18next.t('text.quote.memberRenewalDate'),
      headerClasses: 'sorting',
      sorter: false,
      exportKey: 'CustomerMembership',
      export: true,
      exportFormatter: (cell, row) =>
        showDateInBrowser(
          row?.CustomerMembership?.endDateTime,
          DATE_TH_MONTH_YEAR
        ),
      render: (cell, row) =>
        row?.CustomerMembership?.endDateTime
          ? showDateInBrowser(
              row?.CustomerMembership?.endDateTime,
              DATE_TH_MONTH_YEAR
            )
          : '-',
      ...getFromToDateFilter({
        dataStartIndex: 'membershipFromDate',
        dataEndIndex: 'membershipToDate',
        placeholderStartDate: 'From date',
        placeholderEndDate: 'To date',
        dateFormat: 'YYYY/MM/DD',
        onFilterChange,
        filter,
      }),
    },
    {
      dataIndex: 'status',
      title: i18next.t('text.common.status'),
      headerClasses: 'sorting',
      exportName: 'Status',
      sorter: true,
      onSort: (field, order) => onSort('status', order),
      render: (cell, row) => {
        return (
          <>
            <Toggle row={row} cell={cell} />
          </>
        );
      },
      ...getColumnSelectSearchProps({
        dataIndex: 'status',
        name: 'status',
        onFilterChange,
        filter,
        mode: false,
        list: getStatusData(),
      }),
    },
    {
      dataIndex: 'action',
      title: i18next.t('text.common.action'),
      className: 'nk-tb-col-tools text-end',
      render: (cell, row) => {
        return <Action row={row} cell={cell} />;
      },
    },
    {
      dataIndex: 'CustomerAddresses',
      title: i18next.t('text.customers.viewUserAddress'),
      exportName: 'Addresses',
      isHidden: true,
      sort: false,
      export: true,
      // onSort: (field, order) => onSort('userAddress', order),
      exportFormatter: (cell, row) =>
        (cell || []).map((e) => e?.addressLine1).join(', '),
    },
  ];
  if (userRole !== 'nca') {
    newCustomerColumn.splice(3, 0, {
      dataIndex: 'userCompany',
      exportKey: 'Customer',
      title: i18next.t('text.customers.usersCompany'),
      headerClasses: 'sorting',
      sorter: true,
      sortType: 'userCompany',
      exportFormatter: (cell, row) =>
        row?.Customer?.CompanyNca?.name
          ? row?.Customer?.CompanyNca?.name
          : i18next.t('text.common.goodGuysAdmin'),

      render: (cell, row) =>
        row?.Customer?.CompanyNca?.name
          ? row?.Customer?.CompanyNca?.name
          : i18next.t('text.common.goodGuysAdmin'),

      ...getMultiSelectSearchFilter({
        dataIndex: 'userCompany',
        onFilterChange,
        name: 'company',
        list: [],
        filter,
        mode: false,
        // scope: 'activeCompany',
        listKeys: { nameKey: 'name', nameId: 'name' },
        listService: listAdminNCACompanyService,
        innerKey: 'CompanyNca',
      }),
    });

  }
  return newCustomerColumn;
};

export const customersBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: '#',
      name: i18next.t('text.customers.heading'),
    },
  ];
};
export const addUserBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: customerRoute.CUSTOMERS.path,
      name: i18next.t('text.customers.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.customers.addUser'),
    },
  ];
};
export const editUserBreadcrumb = () => {
  return [
    {
      path: accessRoute.DASHBOARD.path,
      name: i18next.t('text.common.dashboard'),
    },
    {
      path: customerRoute.CUSTOMERS.path,
      name: i18next.t('text.customers.heading'),
    },
    {
      path: '#',
      name: i18next.t('text.customers.editUser'),
    },
  ];
};

export const customerEditTabContent = ({
  id,
  memberShipModal,
  formData,
  setFormData,
  isOpenModal,
  setMemberShipAPIData,
  memberShipAPIData,
  isAlertVisible,
  // userRole,
  isNca,
  isMembershipPermission
}) => {
  return [
    {
      name: i18next.t('text.memberShip.memberShipCustomer'),
      key: 'customerDetails',
      content: (
        <>
          <CustomerDetails
            formData={formData}
            setFormData={setFormData}
            membershipData={memberShipAPIData}
          />
        </>
      ),
    },
    (isNca && !isMembershipPermission) ?
      '' :
      {
        name: i18next.t('text.memberShip.heading'),
        key: 'memberShipPlan',
        content: (
          <>
            <MemberShipPlan
              id={id}
              formData={formData}
              memberShipUpgradeModalShow={memberShipModal}
              isOpenModal={isOpenModal}
              setMemberShipData={setMemberShipAPIData}
              memberShipData={memberShipAPIData}
              isAlertVisible={isAlertVisible}
            />
          </>
        ),
      }
  ];
};
